import React from 'react';
import SidebarMenu from '../SidebarMenu';

import { MdInsights, MdDesignServices, MdOutlineCategory } from "react-icons/md"
import { FaAd, FaTasks } from "react-icons/fa"
import { VscFeedback } from "react-icons/vsc"
import { HiBell, HiOutlineViewGridAdd } from "react-icons/hi"
import { GiLog } from "react-icons/gi"
import { TbApps, TbSitemap } from "react-icons/tb"

export const SidebarMiddleNav = () => {
    return (
        <SidebarMenu>
            <SidebarMenu.Item 
                title="Dashboard" 
                to={'/specular/dashboard'} 
                exact
                icon={<i className="fa fa-fw fa-home"></i>}
            />
            <SidebarMenu.Item 
                title="Event Planner" 
                to={'/specular/events'} 
                exact
                icon={<FaTasks size="19" style={{ marginRight: "8px" }} />}
            />
            <SidebarMenu.Item 
                title="Customize Layout" 
                to={'/specular/layout'} 
                exact
                icon={<MdDesignServices size="19" style={{ marginRight: "8px" }} />}
            />
            <SidebarMenu.Item 
                title="Control Applications" 
                to={'/specular/apps'} 
                exact
                icon={<HiOutlineViewGridAdd size="19" style={{ marginRight: "8px" }} />}
            />
            <SidebarMenu.Item 
                title="Mirror KPIs" 
                to={'/specular/kpi'} 
                exact
                icon={<MdInsights size="19" style={{ marginRight: "8px" }} />}
            />
            <SidebarMenu.Item 
                title="Advertisements" 
                to={'/specular/ads'} 
                exact
                icon={<FaAd size="19" style={{ marginRight: "8px" }} />}
            />
            <SidebarMenu.Item 
                title="CV Categories" 
                to={'/cv/categories'} 
                exact
                icon={<MdOutlineCategory size="19" style={{ marginRight: "8px" }} />}
            />
            <SidebarMenu.Item 
                title="CV Items" 
                to={'/cv/items'} 
                exact
                icon={<TbSitemap size="19" style={{ marginRight: "8px" }} />}
            />
            <SidebarMenu.Item 
                title="Tasks" 
                to={'/specular/tasks'} 
                exact
                icon={<FaTasks size="19" style={{ marginRight: "8px" }} />}
            />
            <SidebarMenu.Item 
                title="Feedback" 
                to={'/specular/feedback'} 
                exact
                icon={<VscFeedback size="19" style={{ marginRight: "8px" }} />}
            />
            <SidebarMenu.Item 
                title="Alerts" 
                to={'/specular/alerts'} 
                exact
                icon={<HiBell size="19" style={{ marginRight: "8px" }} />}
            />
            <SidebarMenu.Item 
                title="Logs" 
                to={'/specular/logs'} 
                exact
                icon={<GiLog size="19" style={{ marginRight: "8px" }} />}
            />
        </SidebarMenu>
    )
}
