import React, { useState } from "react";
import {
    Modal, Label, ModalBody, ModalFooter,
    Container, Row, Col
} from "reactstrap";
import PropTypes from "prop-types";
import { GboxFormRow as FormRow } from "./FormRow";
import { GboxButton as Button } from "./Button";

function DeletionModal(props) {
    const [modalOpened, setModalOpen] = useState(false);

    function toggleMe() {
        setModalOpen(!modalOpened);
    }

    return (
        <Button onClick={() => setModalOpen(!modalOpened)} color="danger">
            Delete
            <Modal isOpen={modalOpened}
                toggle={toggleMe}
                className="modal-outline-dark">
                <ModalBody className="text-center px-3">
                    <Container>
                        <Row>
                            <Col className="text-center py-3">
                                <h2>Confirm Deletion</h2>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormRow>
                                    <Label sm={12}>
                                        Are you sure you want to delete {props.assetName}?
                                    </Label>
                                </FormRow>
                            </Col>
                        </Row>
                    </Container>
                </ModalBody>
                <ModalFooter className="py-3">
                    <Button color="danger" outline={false} className="mr-2" onClick={() => {
                        props.successFunction(props.successFunctionProp);
                        setModalOpen(false);
                    }}>
                        Delete
                    </Button>
                    <Button className="text-info" onClick={() => { setModalOpen(false) }}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        </Button>
    );
}

DeletionModal.propTypes = {
    assetName: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    successFunction: PropTypes.func,
    successFunctionProp: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

DeletionModal.defaultProps = {
    assetName: 'UNDEFINED',
    successFunction: () => { return; }
}

export default DeletionModal;