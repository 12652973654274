import React, { useState, useEffect } from 'react';
import { Row, Col, CardBody, Card, Container } from 'reactstrap';
import {Link} from "react-router-dom";
import { AdvancedTable, ButtonGroup, Button, DeletionModal } from '../../components';
import { HeaderMain } from "../../components/HeaderMain";
import { useSocket, prepareSystemServiceQuery } from '../../contexts/SocketContext';

export function Users() {
    const {socket} = useSocket();

    const [ruleValues, setRuleValues] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        refreshValues();
        const interval = setInterval(() => {
            refreshValues();
        }, 30000);
        return () => clearInterval(interval);

    }, [socket]);

    function refreshValues() {
        if(socket !== null && socket.isOpened) {
            setLoading(true)
            socket.sendRequest(prepareSystemServiceQuery("GET", "user")).then(res => {
                if(res.response !== undefined) {
                    setRuleValues(res.response);
                    setLoading(false)
                }
            });
        }
    }

    function deleteValue(cell) {
        if (socket !== null && socket.isOpened) {
            setRuleValues([]);
            setLoading(true);
            if (socket !== null && socket.isOpened) {
                socket.sendRequest(prepareSystemServiceQuery("DELETE", "user", {id: cell})).then(() => {
                    refreshValues()
                })
            }
        }
    }
    
    return (
        <Container>
            <Row className="mb-5">
                <Col lg={12}>
                    <HeaderMain
                        title="Users"
                        className="mb-3"
                    />
                </Col>
                <Col lg={12}>
                    <Card>
                        <CardBody>
                            <AdvancedTable
                                to="/app/users/add"
                                loading={loading}
                                columns={[{
                                        dataField: 'email',
                                        text: 'Email',
                                        sort: true
                                    }, {
                                        dataField: "actions",
                                        text: "Actions",
                                        sort: false,
                                        formatter: function name(cell) {
                                            return (
                                                <ButtonGroup>
                                                    <DeletionModal assetName={cell[1]}
                                                        successFunction={deleteValue}
                                                        successFunctionProp={cell[0] + ""} />
                                                    <Button color="primary" tag={Link} to={{
                                                            pathname: '/app/users/edit/' + cell[0],
                                                            id: cell[0],
                                                        }}>
                                                        Edit
                                                    </Button>
                                                </ButtonGroup>
                                            );
                                        } 
                                    }
                                ]}
                                keyField="username"
                                data={
                                    Array.isArray(ruleValues) ? ruleValues.map(returnResource => ({
                                        ...returnResource,
                                        actions: [returnResource._id, returnResource.email]
                                    })) : []
                                }
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>

    );
}

export default Users;