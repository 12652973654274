import React, { useState } from "react";
import {
    Modal, Label, ModalBody, ModalFooter,
    Container, Row, Col,
    Input
} from "reactstrap";
import PropTypes from "prop-types";
import { GboxFormRow as FormRow } from "./FormRow";
import { GboxButton as Button } from "./Button";

function UpdateModal({cell, ...props}) {
    const [modalOpened, setModalOpen] = useState(false);
    const [cellObjs, setCellObjs] = useState({...cell});
    
    function toggleMe() {
        setModalOpen(!modalOpened);
    }

    return (
        <Button onClick={() => setModalOpen(!modalOpened)} color="success">
            Update
            <Modal isOpen={modalOpened}
                toggle={toggleMe}
                className="modal-outline-dark">
                <ModalBody className="text-center px-3">
                    <Container>
                    <Row>
                        <Col className="text-center py-3">
                            <h2>Update</h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-center py-3">
                            <Label for="logo" id="logoLabel2" sm={3}>
                                Name
                            </Label>
                            <Input type="text" id="name" maxLength="52" value={cellObjs.name} onChange={e => setCellObjs({...cellObjs, name: e.target.value})} />
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-center py-3">
                            <Label for="logo" id="logoLabel2" sm={3}>
                                Phone
                            </Label>
                            <Input type="text" id="name" maxLength="52" value={cellObjs.phone} onChange={e => {setCellObjs({...cellObjs, phone: e.target.value})}} />
                        </Col>
                    </Row>
                    </Container>
                </ModalBody>
                <ModalFooter className="py-3">
                    <Button color="success" outline={false} className="mr-2" onClick={() => {
                        props.successFunction(cellObjs);
                        setModalOpen(false);
                    }}>
                        Update/Add
                    </Button>
                    <Button className="text-info" onClick={() => { setModalOpen(false) }}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        </Button>
    );
}

UpdateModal.propTypes = {
    assetName: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    successFunction: PropTypes.func,
    //successFunctionProp: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    children: PropTypes.any
}

UpdateModal.defaultProps = {
    assetName: 'UNDEFINED',
    successFunction: () => { return; }
}

export default UpdateModal;